/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Image from '../../shared/Image/index';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { LazyImage } from 'components/LazyImage';

const playButton = {
  handle: 'SLQOzrORluusj8VkO7ag',
  width: 384,
  height: 382
};

const VideoInline = props => {
  const {
    video,
    poster,
    thumbnailProps,
    renderThumbnail,
    videoProps,
    alt,
    controlsList,
    onContextMenu,
    isAutoPlay,
    ...rest
  } = props;
  const [isShowThumbnail, showThumbnail] = React.useState(false);
  React.useEffect(() => {
    showThumbnail(!!poster);
  }, [poster]);
  return (
    <div {...rest}>
      {isShowThumbnail ? (
        <ThumbnailWrapper
          className="thumbnail"
          onClick={() => showThumbnail(false)}
          {...thumbnailProps}
        >
          {renderThumbnail ? (
            renderThumbnail()
          ) : (
            <LazyImage
              src={getAssetLinkByHandle({ handle: poster })}
              alt={alt}
            />
          )}
          <div className="backdrop" />
          <div className="playButton">
            <div className="playButtonContentWrp">
              <LazyImage
                handle="cm8h4dl7z025k07zvcu044rt6"
                className="icon top"
                alt="icon-star"
              />
            </div>
          </div>
        </ThumbnailWrapper>
      ) : (
        <video
          onContextMenu={onContextMenu}
          className="video-player"
          controls
          controlsList={controlsList}
          preload="meta"
          autoPlay={isAutoPlay ? isAutoPlay : !!poster}
          id="video-auto-play-modal"
          style={{ width: '100%' }}
          src={video}
          type="video/mp4"
          {...videoProps}
        >
          <track kind="captions" />
        </video>
      )}
    </div>
  );
};

export default VideoInline;

VideoInline.defaultProps = {
  video: null,
  poster: null,
  renderThumbnail: null,
  controlsList: null,
  onContextMenu: null,
  isAutoPlay: false
};

VideoInline.propTypes = {
  video: PropTypes.string,
  poster: PropTypes.PropTypes.string,
  renderThumbnail: PropTypes.func,
  videoProps: PropTypes.any,
  controlsList: PropTypes.string,
  onContextMenu: PropTypes.func,
  isAutoPlay: PropTypes.bool
};

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  position: relative;
  margin-bottom: 1em;
  cursor: pointer;
  background-color: whitesmoke;
  border-radius: 1em;
  overflow: hidden;
  .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(36, 44, 52, 0.4);
  }
  .playButton {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: 2;
    overflow: hidden;
  }

  .playButtonContentWrp {
    width: 66px;
    height: 66px;
    margin: -1px;
  }
`;
